<table>
    @for (level of data; track level) {
        <tr>
            <td>
                <div class="input-wrapper">
                    <assist-checkbox
                        [class.subselected]="childSelected(level)"
                        [attr.id]="level.key"
                        [checked]="level.selected"
                        (change)="onSelect(level, !level.selected)"
                    ></assist-checkbox>
                </div>
            </td>
            <td (click)="level.open = !level.open" class="suplevel">
                @if (!level.open && level.children.length > 0) {
                    <cue-icon [icon]="ICONS.cueCaretRight"></cue-icon>
                }
                @if (level.open && level.children.length > 0) {
                    <cue-icon [icon]="ICONS.cueCaretDown"></cue-icon>
                }
                <span class="parent-label">{{ level.name | localizedString }} </span>
            </td>
        </tr>

        <ng-container
            [ngTemplateOutlet]="sublevel"
            [ngTemplateOutletContext]="{ $implicit: level, offset: 1, brightness: 100 }"
        ></ng-container>
        <ng-template #sublevel let-parent let-offset="offset" let-brightness="brightness">
            @if (parent.open) {
                @for (subLevel of parent.children; track subLevel) {
                    <tr class="sublevel">
                        <td>
                            <div class="input-wrapper">
                                <assist-checkbox
                                    [class.subselected]="childSelected(subLevel)"
                                    class="marianova-classa"
                                    type="checkbox"
                                    [attr.id]="subLevel.key"
                                    [checked]="subLevel.selected"
                                    (change)="onSelect(subLevel, !subLevel.selected)"
                                >
                                </assist-checkbox>
                            </div>
                        </td>
                        <td
                            [style.padding-left]="offset * 25 + 'px'"
                            (click)="subLevel.open = !subLevel.open"
                            [class]="subLevel.children.length > 0 ? 'suplevel' : 'with-line'"
                        >
                            @if (!subLevel.open && subLevel.children.length > 0) {
                                <cue-icon [icon]="ICONS.cueCaretRight"></cue-icon>
                            }
                            @if (subLevel.open && subLevel.children.length > 0) {
                                <cue-icon [icon]="ICONS.cueCaretDown"></cue-icon>
                            }
                            <span [class]="subLevel.children.length > 0 ? 'parent-label' : 'item-label'"
                                >{{ subLevel.name | localizedString }}
                            </span>
                        </td>
                    </tr>
                    <ng-container
                        [ngTemplateOutlet]="sublevel"
                        [ngTemplateOutletContext]="{
                            $implicit: subLevel,
                            offset: offset + 1,
                            brightness: brightness + 10,
                        }"
                    ></ng-container>
                }
            }
        </ng-template>
    }
</table>
